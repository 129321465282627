<template>
  <div class="m-0">
    <Alert />
    <div class="titre-container rs-title-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr">
        Détail du bordereau de cotisations Urssaf
      </h1>
    </div>
    <div class="text-left">
      <a
        href="#"
        @click="goPrevious($event)"
        class="fr-link p-0 color-pr fs-875 rs-mb-625"
        title="Retour au détail de la déclaration"
        ><i class="ri-arrow-left-line ml-1"></i>Retour au détail</a
      >
    </div>

    <div class="bloc-header">
      <div class="fr-grid-row">
        <div class="fr-col-lg-9">
          <div class="switcher">
            <div class="icon">
              <span class="ri-store-3-fill color-pr" />
            </div>

            <div class="fr-grid-row">
              <div class="fr-col-12">
                <div class="text-gray">&Eacute;tablissement</div>
              </div>
              <div v-if="etablissement" class="rs-width">
                <div class="fs-b">
                  {{ etablissement.raisonSocialeEtablissement }}
                </div>
              </div>
              <i v-if="etablissement" class="ri-subtract-fill show-element"></i>
              <div v-if="detail" class="rs-width">
                <div class="rs-b">
                  SIRET : {{ $filters.sirenFormat(detail.siret) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fr-col-lg-3">
          <div class="switcher">
            <div class="fr-col-12">
              <div class="text-gray">Période de rattachement</div>
              <div>
                du
                {{
                  bordereau.periode &&
                  $filters.dateFormat(bordereau.periode.dateDebut)
                }}
                au
                {{
                  bordereau.periode &&
                  $filters.dateFormat(bordereau.periode.dateFin)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="fs-b">
        Détail de la déclaration déposée le
        {{ $filters.dateFormat(bordereau.dateDepot) }} (fraction
        {{ bordereau.fraction }}) pour
        {{ bordereau.periode ? bordereau.periode.libelleLong : '' }}
      </h2>
      <table class="table fr-table">
        <caption>
          Détail de la déclaration déposée
        </caption>
        <thead>
          <tr>
            <th scope="col">Catégorie de salariés</th>
            <th scope="col">Code</th>
            <th scope="col">Type de base</th>
            <th scope="col" class="width-10">Taux en %</th>
            <th scope="col" class="text-right">Base déclarée</th>
            <th scope="col" class="text-right">Cotisations déclarées</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in bordereau.bordereauAppele" :key="index">
            <td>
              {{ item.categorieSalaries }}
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              {{ item.sousType }}
            </td>
            <td>{{ item.taux }} %</td>
            <td class="text-right fs-b">
              <span class="sr-only">
                {{ $filters.numberSpacedRgaa(item.base) }}</span
              >
              <span aria-hidden="true">
                {{ $filters.numberSpaced(item.base) }}
              </span>
            </td>
            <td class="text-right fs-b">
              <span class="sr-only">
                {{ $filters.numberSpacedRgaa(item.cotisationsDeclarees) }}</span
              >
              <span aria-hidden="true">
                {{ $filters.numberSpaced(item.cotisationsDeclarees) }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6" class="text-right">
              Total déclaré :
              <span class="sr-only">
                {{
                  $filters.numberSpacedRgaa(bordereau.montantTotalDeclare)
                }}</span
              >
              <span aria-hidden="true" class="fs-b">
                {{ $filters.numberSpaced(bordereau.montantTotalDeclare) }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      v-if="
        bordereau &&
        bordereau.cumulBordereauxPeriode &&
        bordereau.cumulBordereauxPeriode.length
      "
    >
      <h2 class="fs-b">
        Montant des cotisations enregistrées par l'Urssaf pour
        {{ bordereau.periode ? bordereau.periode.libelleLong : '' }}
      </h2>
      <span
        >Le tableau ci-dessous cumule la déclaration initiale et les éventuelles
        régularisations effectuées par l'entreprise et/ou l'Urssaf.<br />
        Seules les déclarations traitées après échéance par l'Urssaf sont
        comptabilisées dans le tableau ci-dessous.
      </span>
      <table class="table fr-table">
        <caption>
          Montant des cotisations enregistrées par l'Urssaf
        </caption>
        <thead>
          <tr>
            <th scope="col">Catégorie de salariés</th>
            <th scope="col">Code</th>
            <th scope="col">Type de base</th>
            <th scope="col" class="width-10">Taux en %</th>
            <th scope="col" class="text-right">Base enregistrée</th>
            <th scope="col" class="text-right">Cotisation enregistrée</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in bordereau.cumulBordereauxPeriode"
            :key="index"
          >
            <td>
              {{ item.categorieSalaries }}
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              {{ item.sousType }}
            </td>
            <td>{{ item.taux }} %</td>
            <td class="text-right fs-b">
              <span class="sr-only">
                {{ $filters.numberSpacedRgaa(item.base) }}</span
              >
              <span aria-hidden="true">
                {{ $filters.numberSpaced(item.base) }}
              </span>
            </td>
            <td class="text-right fs-b">
              <span class="sr-only">
                {{ $filters.numberSpacedRgaa(item.cotisationsArrondies) }}</span
              >
              <span aria-hidden="true">
                {{ item.cotisationsArrondies }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6" class="text-right">
              Total enregistré :
              <span class="sr-only">
                {{ bordereau.montantTotalEnregistre }}</span
              >
              <span aria-hidden="true" class="fs-b">
                {{ bordereau.montantTotalEnregistre }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="row effectif">
      <div class="col-10">
        Effectif moyen mensuel calculé par l'Urssaf à partir de vos déclarations
        pour {{ bordereau.periode ? bordereau.periode.libelleLong : '' }}
      </div>
      <div class="col mt-0 fs-b color-pr effectif-mensuel">
        <i class="ri-team-fill"></i>
        <div :class="{ texte: effectifMoyenMensuel == 'En cours de calcul' }">
          {{ effectifMoyenMensuel }}
        </div>
      </div>
    </div>
    <p class="text-gray notabene">
      Ces informations sont données à titre indicatif et ne sont pas
      nécessairement exhaustives.
    </p>
  </div>
</template>

<script>
import Alert from '../shared/Alert';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { SOURCE_PAGE_DETAIL_BORDEREAU_COTISATION } from '../constantes';

import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    Alert
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      bordereau: {}
    };
  },
  computed: {
    ...mapGetters(['etablissement']),
    detail() {
      return JSON.parse(localStorage.getItem('detailDeclaration'));
    },
    effectifMoyenMensuel() {
      return this.bordereau.effectifMoyenMensuel
        ? this.bordereau.effectifMoyenMensuel
        : 'En cours de calcul';
    }
  },
  methods: {
    ...mapActions(['setRouteData']),
    goPrevious(event) {
      if (event) {
        event.preventDefault();
      }
      /*
          item: déclaration consultée
      */
      const item = JSON.parse(localStorage.getItem('detailDeclaration'));
      const routeData = {
        source: SOURCE_PAGE_DETAIL_BORDEREAU_COTISATION,
        item: item
      };
      this.setRouteDataAndPush(routeData, 'Détail déclaration');
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  mounted() {
    this.bordereau = localStorage.getItem('detailBordereauCotisations')
      ? JSON.parse(localStorage.getItem('detailBordereauCotisations'))
      : {};
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.25rem;
}
.icon {
  margin-right: 10px;
}
.switcher {
  border-bottom: 1px solid #ddd;
  padding: 16px;
  display: flex;
  align-items: center;
}
.effectif {
  background-color: rgba(224, 224, 224, 0.5);
  padding: 1.2rem;
}
.effectif-mensuel {
  font-size: 1.5rem;
  display: flex;
}
.effectif-mensuel div {
  margin-left: inherit;
}
.bloc-header {
  margin-bottom: 2rem;
}
.fr-table {
  margin-bottom: 3rem;
  td {
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  th {
    padding-left: 2rem;
  }
  th.text-right {
    padding-right: 2rem;
  }
  tfoot {
    background-color: black;
    color: white;
    padding-right: 2rem;
  }
}

.notabene {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
}
.width-10 {
  width: 10%;
}
div.texte {
  font-size: 15px;
  padding-top: 0.5rem;
}
</style>
